// extracted by mini-css-extract-plugin
export var Breadcrumb = "style-module--Breadcrumb--khSNL";
export var BackArrow = "style-module--BackArrow--2pVSK";
export var Article = "style-module--Article--3qa91";
export var Header = "style-module--Header--2z22J";
export var Banner = "style-module--Banner--33Cfm";
export var Body = "style-module--Body--1v1t_";
export var Footer = "style-module--Footer--1BPwq";
export var Category = "style-module--Category--3aNHs";
export var Details = "style-module--Details--3qqNv";
export var ReadingTime = "style-module--ReadingTime--38y79";
export var BannerImage = "style-module--BannerImage--G9z29";
export var BannerCaption = "style-module--BannerCaption--2Jjp3";
export var Content = "style-module--Content--UtIm6";
export var Keyword = "style-module--Keyword--2wnrw";